*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-x: hidden;
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}

body {
  color: #444;
  font-family: Nunito, sans-serif;
  font-weight: 500;
  line-height: 1.6;
  overflow: hidden;
}

section {
  opacity: 1;
  scroll-margin-top: 7rem;
  transition: all .7s;
  transform: translateY(0);
}

section.section--hidden {
  opacity: 0;
  scroll-margin-top: 15rem;
  transform: translateY(8rem);
}

::selection {
  color: #101d2c;
  background-color: #c69963;
}

.heading-1 {
  text-transform: uppercase;
  color: #f9f7f6;
  word-spacing: 5px;
  letter-spacing: 2px;
  align-self: start;
  margin-left: -.5rem;
  font-family: Montserrat, sans-serif;
  font-size: 6.2rem;
  font-weight: 400;
  line-height: 1.2;
}

@media only screen and (max-width: 56.25em) {
  .heading-1 {
    margin-bottom: 3rem;
    font-size: 5.8rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .heading-1 {
    font-size: 5.2rem;
  }
}

.heading-1 span {
  color: #c69963;
}

.heading-3 {
  text-transform: uppercase;
  color: #c69963;
  word-spacing: 2px;
  font-family: Montserrat, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}

@media only screen and (max-width: 37.5em) {
  .heading-3 {
    font-size: 2rem;
  }
}

.heading-2 {
  color: #54483a;
  font-size: 4rem;
  font-weight: 300;
  line-height: 1;
}

@media only screen and (max-width: 37.5em) {
  .heading-2 {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 79.0652em) {
  .heading-break {
    display: none;
  }
}

.italic {
  font-style: italic;
}

.wh {
  color: #f9f7f6;
}

.text-center {
  text-align: center;
}

.gold {
  color: #c69963;
}

.mb-esm {
  margin-bottom: 1rem !important;
}

.mb-sm {
  margin-bottom: 2rem !important;
}

.mb-md {
  margin-bottom: 3rem !important;
}

.mb-lg {
  margin-bottom: 4rem !important;
}

.mb-hg {
  margin-bottom: 8rem !important;
}

.btn {
  text-transform: uppercase;
  word-spacing: 1.5px;
  letter-spacing: 1.5px;
  padding: 1.8rem 3rem;
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

@media only screen and (max-width: 37.5em) {
  .btn {
    padding: 1.6rem 2.8rem;
    font-size: 1.5rem;
  }
}

.btn:link, .btn:visited {
  color: #fff;
  background-color: #c69963;
  transition: all .3s;
}

.btn:hover, .btn:active {
  background-color: #b28451;
}

.btn--submit {
  cursor: pointer;
  color: #fff;
  word-spacing: 1.5px;
  letter-spacing: 1.5px;
  background-color: #c69963;
  border: none;
  outline: none;
  font-family: Montserrat, sans-serif;
  transition: all .3s;
  display: inline-block;
}

.overlay {
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 100;
  background-color: #101d2ccc;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

@keyframes bounce {
  0% {
    transform: translate3d(0, -5px, 0);
  }

  100% {
    transform: translate3d(0, 1px, 0);
  }
}

.header {
  height: 100vh;
  grid-template-rows: 1fr -webkit-min-content minmax(12rem, -webkit-min-content) 1fr;
  background-image: linear-gradient(#101d2ce6, #101d2ce6), url("hero-img.bb9c1bb1.jpg");
  background-position: center;
  background-size: cover;
  grid-template-rows: 1fr min-content minmax(12rem, min-content) 1fr;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  padding: 3.2rem 5.4rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .header {
    grid-template-rows: 1fr -webkit-min-content minmax(10rem, -webkit-min-content) 1fr;
    grid-template-rows: 1fr min-content minmax(10rem, min-content) 1fr;
  }
}

@media only screen and (max-width: 37.5em) {
  .header {
    padding: 3.2rem;
  }
}

.header__cta {
  align-self: start;
}

.nav__list, .nav__list--links {
  align-items: center;
  list-style: none;
  display: flex;
}

@media only screen and (max-width: 52.25em) {
  .nav__list--links {
    height: 100vh;
    width: 100vw;
    -webkit-backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    z-index: 1000;
    background-color: #101d2ce6;
    flex-direction: column;
    justify-content: center;
    transition: all .3s;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }

  .nav__list--links.nav-open {
    transform: translateY(0);
  }
}

.nav__item--logo {
  margin-right: auto;
}

.nav__item--logo-link {
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav__item--icon {
  align-items: center;
  margin-left: 3.8rem;
  display: flex;
}

@media only screen and (max-width: 52.25em) {
  .nav__item--icon {
    margin-bottom: 1rem;
    margin-left: 0;
  }

  .nav__item--icon:not(:last-child) {
    margin-top: 3rem;
  }
}

.nav__item--icon:last-child {
  margin-left: 2.4rem;
}

@media only screen and (max-width: 52.25em) {
  .nav__item--icon:last-child {
    margin-left: 0;
  }
}

.nav__item--link {
  margin-left: 2.4rem;
}

@media only screen and (max-width: 52.25em) {
  .nav__item--link {
    margin-bottom: 1.8rem;
    margin-left: 0;
  }
}

.nav__item-only-mob {
  display: none;
}

.nav-open .nav__item-only-mob {
  display: block;
}

.nav__link:link, .nav__link:visited {
  color: #c69963;
  text-transform: uppercase;
  font-size: 1.7rem;
  line-height: 1.2;
  text-decoration: none;
  transition: all .3s;
}

@media only screen and (max-width: 52.25em) {
  .nav__link:link, .nav__link:visited {
    font-size: 2.8rem;
  }
}

.nav__link:link .icon, .nav__link:visited .icon {
  height: 3.2rem;
  width: 3.2rem;
}

@media only screen and (max-width: 56.25em) {
  .nav__link:link .icon, .nav__link:visited .icon {
    height: 2.8rem;
    width: 2.8rem;
  }
}

@media only screen and (max-width: 52.25em) {
  .nav__link:link .icon, .nav__link:visited .icon {
    height: 4.2rem;
    width: 4.2rem;
  }
}

.nav__link:hover, .nav__link:active {
  color: #b28451;
}

.nav__item--link-grouped > .nav__link--grouper {
  visibility: hidden;
  transform: translate3d(-2rem, -5px, 0);
}

.nav__item--link-grouped:hover > .nav__link--grouper {
  visibility: visible;
  animation: fade-in 1s;
  transform: translate3d(-2rem, 1px, 0);
}

.nav__item--link-grouped:hover > .nav__link {
  opacity: .5;
}

.nav-open .nav__item--link-grouped {
  display: none;
}

.nav__link--grouper {
  padding: 0 2rem 2rem;
  list-style: none;
  transition: all 1s;
  position: absolute;
}

.nav__link--grouper > li {
  margin-top: 1.2rem;
}

.nav__logo {
  height: 6.4rem;
  width: auto;
}

@media only screen and (max-width: 37.5em) {
  .nav__logo {
    height: 4.8rem;
  }
}

.nav__mob-logo {
  width: 5rem;
  height: auto;
  display: none;
}

@media only screen and (max-width: 52.25em) {
  .nav__mob-logo {
    display: block;
    position: absolute;
    top: 3.2rem;
    left: 3.2rem;
  }
}

.nav__btn--mob {
  display: none;
}

@media only screen and (max-width: 52.25em) {
  .nav__btn--mob {
    cursor: pointer;
    color: #c69963;
    background-color: #0000;
    border: none;
    outline: none;
    justify-content: center;
    align-items: center;
    animation: bounce .8s cubic-bezier(.7, 0, .3, 1) infinite alternate;
    display: flex;
  }
}

.nav__btn--mob svg {
  height: 3.8rem;
  width: 3.8rem;
}

.nav__btn--mob-close {
  display: none;
}

@media only screen and (max-width: 52.25em) {
  .nav__btn--mob-close {
    cursor: pointer;
    color: #c69963;
    background-color: #0000;
    border: none;
    outline: none;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 3.2rem;
    right: 3.2rem;
  }
}

.nav__btn--mob-close svg {
  height: 3.8rem;
  width: 3.8rem;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translate3d(-2rem, -5px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(-2rem, 1px, 0);
  }
}

.sticky-header {
  width: 100vw;
  z-index: 999;
  background-color: #101d2c;
  padding: 1.4rem 3.2rem;
  position: fixed;
  top: 0;
  left: 0;
}

.sticky-header .nav__logo {
  height: 5.4rem;
}

.sticky-header .nav__link--grouper {
  background-color: #101d2c;
}

.intro {
  background-color: #f9f7f6;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media only screen and (max-width: 53.125em) {
  .intro {
    grid-template-columns: 1fr;
  }
}

.intro__picture {
  background-image: linear-gradient(#c6996340, #c6996340), url("about-1.a199948e.jpg");
  background-position: 100%;
  background-size: cover;
}

@media only screen and (max-width: 53.125em) {
  .intro__picture {
    height: 66vh;
  }
}

.intro__content {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 9.6rem 8rem;
  display: flex;
}

@media only screen and (max-width: 53.125em) {
  .intro__content {
    text-align: left;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 37.5em) {
  .intro__content {
    padding: 9rem 3.2rem;
  }
}

.intro__paragraph {
  text-align: justify;
  text-align-last: left;
  margin-bottom: 4rem;
  font-size: 1.7rem;
  font-style: italic;
}

@media only screen and (max-width: 37.5em) {
  .intro__paragraph {
    font-size: 2rem;
  }
}

.services-developers {
  background-color: #f9f7f6;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media only screen and (max-width: 53.125em) {
  .services-developers {
    grid-template-columns: 1fr;
  }
}

.services-developers--left {
  -moz-column-gap: 2.8rem;
  background-image: linear-gradient(#101d2cf2, #101d2cf2), url("hero-img.bb9c1bb1.jpg");
  background-position: center;
  background-size: cover;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 2.2rem 2.8rem;
  padding: 12rem 5.4rem;
  display: grid;
}

@media only screen and (max-width: 53.125em) {
  .services-developers--left {
    -moz-column-gap: 6.4rem;
    grid-row: 2 / 3;
    gap: 5.2rem 6.4rem;
    padding: 9.6rem 8rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .services-developers--left {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, -webkit-min-content);
    grid-template-rows: repeat(4, min-content);
    justify-items: center;
    padding: 9.6rem 3.2rem;
  }
}

.services-developers__content {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12rem 8rem;
  display: flex;
}

@media only screen and (max-width: 53.125em) {
  .services-developers__content {
    text-align: left;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 37.5em) {
  .services-developers__content {
    padding: 9rem 3.2rem;
  }
}

.services-developers__paragraph {
  text-align: justify;
  text-align-last: left;
  margin-bottom: 4rem;
  font-size: 1.7rem;
  font-style: italic;
}

@media only screen and (max-width: 37.5em) {
  .services-developers__paragraph {
    font-size: 2rem;
  }
}

.services-tenants {
  background-color: #f9f7f6;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media only screen and (max-width: 53.125em) {
  .services-tenants {
    grid-template-columns: 1fr;
  }
}

.services-tenants--right {
  -moz-column-gap: 2.8rem;
  background-image: linear-gradient(#101d2cf2, #101d2cf2), url("hero-img.bb9c1bb1.jpg");
  background-position: center;
  background-size: cover;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.8rem;
  padding: 12rem 5.4rem;
  display: grid;
}

@media only screen and (max-width: 53.125em) {
  .services-tenants--right {
    -moz-column-gap: 6.4rem;
    grid-row: 2 / 3;
    gap: 5.2rem 6.4rem;
    padding: 9.6rem 8rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .services-tenants--right {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, -webkit-min-content);
    grid-template-rows: repeat(2, min-content);
    justify-items: center;
    padding: 9.6rem 3.2rem;
  }
}

.services-tenants__content {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12rem 8rem;
  display: flex;
}

@media only screen and (max-width: 53.125em) {
  .services-tenants__content {
    text-align: left;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 37.5em) {
  .services-tenants__content {
    padding: 9rem 3.2rem;
  }
}

.services-tenants__paragraph {
  text-align: justify;
  text-align-last: left;
  margin-bottom: 4rem;
  font-size: 1.7rem;
  font-style: italic;
}

@media only screen and (max-width: 37.5em) {
  .services-tenants__paragraph {
    font-size: 2rem;
  }
}

.features {
  background-color: #f9f5ef;
}

.features-wrapper {
  max-width: 120rem;
  margin: 0 auto;
  padding: 11rem 3.2rem;
}

@media only screen and (min-width: 112.5em) {
  .features-wrapper {
    max-width: 130rem;
  }
}

.features__container {
  -moz-column-gap: 3.2rem;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3.2rem;
  display: grid;
}

@media only screen and (max-width: 75em) {
  .features__container {
    -moz-column-gap: 2.4rem;
    column-gap: 2.4rem;
  }
}

@media only screen and (max-width: 63.75em) {
  .features__container {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 3.2rem;
  }
}

@media only screen and (max-width: 50em) {
  .features__container {
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 3.2rem;
  }
}

.feature {
  height: 48rem;
  position: relative;
}

@media only screen and (max-width: 75em) {
  .feature {
    height: 52rem;
  }
}

@media only screen and (max-width: 63.75em) {
  .feature:last-child {
    grid-column: span 2;
    justify-self: center;
  }
}

@media only screen and (max-width: 50em) {
  .feature {
    width: 40rem;
  }

  .feature:last-child {
    justify-self: unset;
    grid-column: 1;
  }
}

@media only screen and (max-width: 37.5em) {
  .feature {
    height: auto;
  }
}

@media only screen and (max-width: 28.75em) {
  .feature {
    width: 100%;
  }
}

.feature__img {
  height: 24rem;
  z-index: 10;
  background-position: center;
  background-size: cover;
  transition: all .5s;
  position: relative;
  transform: translateY(50%);
}

@media only screen and (max-width: 75em) {
  .feature__img {
    height: 26rem;
  }
}

.feature__img-1 {
  background-image: url("feature-1.0aba2625.jpg");
}

.feature__img-2 {
  background-image: url("feature-2.2394b9fa.jpg");
}

.feature__img-3 {
  background-image: url("feature-3.67ca31ae.jpg");
}

.feature__img-overlay {
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: #101d2ce6;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.feature__img-overlay .feature__bounce-wrapper {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.feature__img-overlay svg {
  width: 2.4rem;
  height: 2.4rem;
  color: #b28451;
  animation: bounce .8s cubic-bezier(.7, 0, .3, 1) infinite alternate;
}

.feature__text-container {
  height: 24rem;
  z-index: 5;
  padding: 2rem;
  transition: all .5s;
  position: relative;
  transform: translateY(-50%);
  box-shadow: 0 2.4rem 4.8rem #0000001a;
}

@media only screen and (max-width: 75em) {
  .feature__text-container {
    height: 25rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .feature__text-container {
    height: auto;
  }
}

.feature__heading {
  text-transform: uppercase;
  color: #b28451;
  align-self: center;
  font-size: 1.8rem;
  font-weight: 700;
}

.feature__list {
  list-style: none;
}

.feature__list .list-item {
  align-items: flex-start;
  display: flex;
}

.feature__list .list-item svg {
  width: 1.7rem;
  height: 1.7rem;
  color: #b28451;
  flex-shrink: 0;
  margin-right: .8rem;
  transform: translateY(2px);
}

.feature__list .list-item p {
  font-size: 1.4rem;
  line-height: 1.4;
}

@media only screen and (max-width: 37.5em) {
  .feature__list .list-item p {
    font-size: 1.8rem;
  }
}

.feature__list .list-item:not(:last-child) {
  margin-bottom: .6rem;
}

.feature:hover .feature__img-overlay {
  opacity: 0;
}

.feature:hover .feature__img, .feature:hover .feature__text-container {
  transform: translateY(0);
}

@media (hover: none) {
  .feature .feature__img {
    transform: translateY(0);
  }

  .feature .feature__text-container {
    height: auto;
    padding: 3.2rem;
    transform: translateY(0);
  }

  .feature .feature__img .feature__img-overlay {
    display: none;
  }
}

.contact {
  background-image: linear-gradient(#101d2cf2, #101d2cf2), url("hero-img.bb9c1bb1.jpg");
  background-position: center;
  background-size: cover;
  padding: 11rem 0;
}

.contact__container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.contact__container--inner {
  -moz-column-gap: 5.4rem;
  border-radius: 2px;
  grid-template-columns: 2fr 1fr;
  column-gap: 5.4rem;
  display: grid;
}

@media only screen and (max-width: 62.5em) {
  .contact__container--inner {
    grid-template-columns: 2.5fr 1fr;
  }
}

@media only screen and (max-width: 56.25em) {
  .contact__container--inner {
    grid-template-columns: 1fr;
  }
}

.contact__text-box .break {
  display: none;
}

@media only screen and (max-width: 31.25em) {
  .contact__text-box .break {
    display: block;
  }
}

.contact__image-box {
  background-image: linear-gradient(#101d2c4d, #101d2c4d), url("contact.9b29b98f.jpg");
  background-position: 100%;
  background-size: cover;
}

@media only screen and (max-width: 56.25em) {
  .contact__image-box {
    display: none;
  }
}

.contact__form {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, -webkit-min-content);
  -moz-column-gap: 5.4rem;
  grid-template-rows: repeat(4, min-content);
  gap: 1.8rem 5.4rem;
  display: grid;
}

@media only screen and (max-width: 37.5em) {
  .contact__form {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, -webkit-min-content);
    grid-template-rows: repeat(5, min-content);
  }
}

.contact__form div {
  flex-direction: column;
  display: flex;
}

.contact__form div.msg {
  grid-area: 1 / 2 / 5 / 3;
}

@media only screen and (max-width: 37.5em) {
  .contact__form div.msg {
    height: 25rem;
    grid-area: 4 / 1 / 5;
  }
}

.contact__form div label {
  color: #b28451;
  text-transform: uppercase;
  margin-bottom: .8rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.contact__form div input {
  width: 100%;
  color: #cfd2d5;
  background-color: #283441;
  border: none;
  padding: 1.2rem;
  font-family: inherit;
  font-size: 1.8rem;
  transition: all .3s;
}

.contact__form div input:focus {
  outline: none;
  box-shadow: 0 0 0 .4rem #c69963;
}

.contact__form div textarea {
  width: 100%;
  height: 100%;
  color: #cfd2d5;
  resize: none;
  background-color: #283441;
  border: none;
  padding: 1.2rem;
  font-family: inherit;
  font-size: 1.8rem;
  transition: all .3s;
}

.contact__form div textarea:focus {
  outline: none;
  box-shadow: 0 0 0 .4rem #c69963;
}

.contact__form .btn--submit {
  align-self: end;
  margin-top: 3rem;
}

.spinner svg {
  height: 6rem;
  width: 6rem;
  fill: #c69963;
  animation: rotate 2s linear infinite;
}

.message {
  text-align: center;
  margin: 0 5.4rem;
}

.message svg {
  height: 6rem;
  width: 6rem;
  fill: #c69963;
  color: #c69963;
  margin-bottom: 2.2rem;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.footer {
  background-color: #101d2c;
  border-top: 1px solid #444;
  padding: 10rem 3.2rem;
}

.footer__logo {
  height: 7rem;
  width: auto;
  margin: 0 auto 5rem;
  display: block;
}

.footer__copyright {
  text-align: center;
  color: #777;
  font-size: 1.4rem;
}

.footer__copyright span {
  color: #b28451;
}

.footer__copyright span.year {
  color: #777;
}

.footer__address {
  text-align: center;
  margin-top: 2.4rem;
  font-size: 1.4rem;
}

.footer__contact {
  text-align: center;
  margin-top: 1rem;
  font-size: 1.4rem;
}

.footer__link {
  text-decoration: none;
}

.footer__link:link, .footer__link:visited {
  color: #777;
  transition: all .3s;
}

.footer__link:hover, .footer__link:active {
  color: #b7bbc0;
}

.card {
  grid-template-columns: -webkit-min-content 1fr;
  grid-template-columns: min-content 1fr;
  grid-template-rows: -webkit-min-content 1fr;
  -moz-column-gap: 2.4rem;
  border-radius: 3px;
  grid-template-rows: min-content 1fr;
  gap: 3.2rem 2.4rem;
  padding: 0 1.4rem;
  transition: all .4s;
  display: grid;
}

@media only screen and (max-width: 62.5em) {
  .card {
    padding: 0;
  }
}

@media only screen and (max-width: 37.5em) {
  .card {
    width: 100%;
  }
}

@media (any-hover: hover) {
  .card:hover {
    transform: scale(1.02)translateY(-1.5rem);
  }
}

.card__icon {
  width: 4.5rem;
  height: 4.5rem;
  fill: #b28451;
  color: #b28451;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
}

.card__heading {
  text-transform: uppercase;
  color: #b28451;
  align-self: center;
  font-size: 1.6rem;
  font-weight: 700;
}

@media only screen and (max-width: 37.5em) {
  .card__heading {
    font-size: 2rem;
  }
}

.card__paragraph {
  text-align: justify;
  color: #b7bbc0;
  grid-column: 1 / -1;
  font-size: 1.6rem;
}

@media only screen and (max-width: 37.5em) {
  .card__paragraph {
    font-size: 2rem;
  }
}

/*# sourceMappingURL=index.c9be220d.css.map */
